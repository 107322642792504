import Vue from "vue";

Vue.mixin({
  data() {
    return {
      defaultLanguage: "",
      containerSpace: null,
    };
  },
  mounted() {
    if (
      this.$application &&
      this.$application.i18n &&
      this.$application.i18n.defaultLocale
    ) {
      this.defaultLanguage = this.$application.i18n.defaultLocale;
    }

    this.$nextTick(function () {
      this.onResizeGetContainerMargin();
    });
    window.addEventListener("resize", this.onResizeGetContainerMargin, {
      passive: true,
    });
  },
  computed: {
    $homepageLink() {
      if (this.$i18n.locale === this.defaultLanguage) {
        return "/";
      } else {
        return `/${this.$i18n.locale}`;
      }
    },
  },
  methods: {
    $formatDate(dateStr) {
      if (!dateStr) return;
      const locale = this.$i18n.locale;
      const date = new Date(dateStr);

      let day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const monthName = this.$getMonthName(date, month, locale);

      if (day < 10) {
        day = "0" + day;
      }

      switch (locale) {
        case "hr":
          return `${day} ${monthName} ${year}`;
        case "en":
          return `${day} ${monthName} ${year}`;

        default:
          return date;
      }
    },
    $getMonthName(date, monthNumber, locale) {
      if (!date) return;
      if (!monthNumber) return;
      if (!locale) return;

      date.setMonth(monthNumber - 1);

      switch (locale) {
        case "hr":
          return date.toLocaleDateString("hr-HR", { month: "short" });
        case "en":
          return date.toLocaleDateString("en-EN", { month: "short" });

        default:
          return monthNumber;
      }
    },
    $scrollIntoView(id) {
      if (!id) return;
      var access = document.getElementById(id);
      if (!access) return;
      access.scrollIntoView({ behavior: "smooth" }, true);
    },
    onResizeGetContainerMargin() {
      const el = document.getElementById("element");
      if (el) {
        const style = window.getComputedStyle(el);

        const viewPortOffset = el.getBoundingClientRect();
        const space = viewPortOffset.left;
        if (window.innerWidth > 768) {
          this.containerSpace = space + parseInt(style.paddingLeft) + "px";
        } else this.containerSpace = 0;
      }
    },
    $returnImgSrc(item) {
      if (!item) return;
      if (item.profileImage) {
        const mediaObj = {
          id: item.id,
          hash: item.profileImage,
          media: item.profileImage,
        };
        return mediaObj;
      }
    },
    async $redirect() {
      if (this.$i18n.locale === "hr") {
        this.$router.push({
          path: "hvala",
        });
      } else {
        this.$router.push({
          path: "/en/thank-you",
        });
      }
    },
  },
});
